import React from 'react'
import {
    BlockTitle,
  } from "../../../../components/Component";
  import Logo from "../../../../assets/logo.png"
function LogoCard() {
  return (
    // <div style={{ background: "rgba(255,255,255, 0.2)", backdropFilter: "blur(5px)" }}>
    <BlockTitle className="fw-normal" tag="h2">
      {/* Nice, Abu Bin Ishtiyak ! */}
     <img src={Logo} />
    </BlockTitle>
    // </div>
  )
}

export default LogoCard